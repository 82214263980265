<template>
	<div :class="$style.newOrder">
		<Title text="Создание рассылки" position="right"/>

		<div :class="$style.newOrder__form">
			<select v-model="subjectId" multiple>
				<option :value="subject.id" v-for="subject in Subjects" :key="subject.id">
					{{ subject.name }}
				</option>
			</select>
			<select v-model="targetId" multiple>
				<option :value="target.id" v-for="target in targets" :key="target.id">
					{{ target.name }}
				</option>
			</select>

			<input
					v-if="targetId[0] === 19"
					v-model="users"
					:class="$style.newOrder__teachers__header_input"
					placeholder="Список пользователей"
			/>

			<input
					v-model="titleValue"
					:class="$style.newOrder__teachers__header_input"
					placeholder="Тема сообщения"
			/>
			<textarea
					:class="$style.newOrder__teachers__header_input"
					placeholder="Текст сообщения"
					rows="5" cols="100"
					v-model="textValue"
			/>
			<button @click="onSubmit">Создание рассылки</button>
		</div>
	</div>
</template>

<script>
  import Title from '@/basic/Title'
  import orders from '@/api/orders'
  import 'vue2-datepicker/index.css'

  export default {
    name: 'NewsLettersNew',
    components: {
      Title
    },
    data() {
      return {
        targetId: [],
        subjectId: [],
        textValue: '',
        users: '',
        titleValue: '',
        targets: [],
        Subjects: [
          {
            id: 1,
            name: 'Пуши'
          }, {
            id: 2,
            name: 'e-mail'
          }
        ]

      }
    },
    computed: {
      filteredTeachers() {
        if (this.filterValue === '') {
          return this.teachers
        }
        return this.teachers?.filter(
          (order) =>
            (order && order && order.id.toString().indexOf(this.filterValue) >= 0) ||
            order.email?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0 ||
            order.phone?.toString().indexOf(this.filterValue.toLowerCase()) >= 0 ||
            // order.status.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0 ||
            order.attributes.lastName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
            0 ||
            order.attributes.firstName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
            0 ||
            order.attributes.middleName?.toLowerCase().indexOf(this.filterValue.toLowerCase()) >=
            0
        )
      }
    },
    watch: {
      targetId() {
        this.users = ''
      },
      subjectId() {
        this.users = ''
      }
    },
    async mounted() {
      const orderInfo = await orders.getTargetInfo()
      this.targets = orderInfo.data.result
    },
    methods: {
      async onSubmit() {
        const onlyMail = this.subjectId.length === 1 && this.subjectId[0] === 2
        if (onlyMail && this.textValue.length > 1500) {
          alert('текст сообщения не может превышать 1500 символов')
        }
        if (this.textValue.length > 120 && !onlyMail) {
          alert('текст сообщения не может превышать 120 символов')
        }
        if (this.targetId.length === 0 || this.subjectId.length === 0 || !this.titleValue || !this.textValue) {
          alert('Нужно выбрать параметры рассылки')
          return
        }
        let recipients = []
        if (this.targetId.length !== 0) {
          recipients = this.targetId.map((el) => {

            return { targetId: el }
          })
        }
        let users = []

        if (this.users.length > 0) {
          try {
            users = this.users
              .replace(/\s/g, '')
              .split(',')
              .reduce((acc, val) => {
                return [...acc, {userId: +val}]
              }, [])
          } catch (e) {
            alert('Неверно введены id пользователей')
            return
          }
        }

        await orders.createNewsletter(
                this.titleValue,
                this.textValue,
                this.subjectId.indexOf(2) !== -1,
                this.subjectId.indexOf(1) !== -1,
                recipients,
                users
        )
        this.$router.push(`/newsletters/`)
      }
    }
  }
</script>

<style lang="scss" module>
	@import '@/assets/styles/colors.scss';

	.newOrder {
		&__teachers {
			&__header {
				display: flex;
				margin: 0 0 2rem;

				&_input {
					border: 1px solid black;
					resize: none;
					padding: 10px;
					padding-top: 5px;
					margin-bottom: 15px;
					margin-left: 0;
				}
			}
		}

		&__table {
			width: 100%;
			height: 85%;
			border: 1px solid $table-border;

			&_title {
				margin-bottom: 30px;
			}

			&__header {
				&_row {
					display: flex;
					justify-content: space-between;
					height: 2.5rem;
					border-bottom: 1px solid $table-border;
				}

				&_column {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1rem;
					border-right: 1px solid $table-border;
					font-size: 1.125rem;
					font-weight: 500;

					img {
						cursor: pointer;
						padding: 10px;
					}

					&:first-child {
						width: 7.5%;
					}

					&:nth-child(n + 2) {
						width: 18.5%;
					}

					&:last-child {
						border: none;
					}
				}
			}

			&__content {
				position: relative;
				display: flex;
				flex-direction: column;
				height: 95%;

				&_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 2.5rem;
					border-bottom: 1px solid $table-border;
					cursor: pointer;

					&_active {
						background: $red;
						border-right: 1px solid $table-border;
					}

					&:hover {
						background: #e7e4e4;
					}

					&_selected {
						background: #0d2494;
						border-right: 1px solid $table-border;
						color: #fff;

						&:hover {
							background: #0d2494;
						}
					}
				}

				&_column {
					display: flex;
					align-items: center;
					height: 2.5rem;
					padding: 0 1rem;
					font-size: 0.875rem;
					font-weight: 500;

					&:first-child {
						width: 7.5%;
						justify-content: center;
					}

					&:nth-child(n + 2) {
						width: 18.5%;
					}

					&:last-child {
						border: none;
					}
				}

				&_rowLine {
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					display: flex;
					justify-content: space-between;
					width: 100%;
					height: 100%;
				}

				&_line {
					border-right: 1px solid $table-border;

					&:first-child {
						width: 7.5%;
					}

					&:nth-child(n + 2) {
						width: 18.5%;
					}

					&:last-child {
						border: none;
					}
				}
			}
		}

		&__form {
			max-width: 300px;
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			&_info {
				position: relative;
				min-width: 275px;
				width: auto;
				margin-right: 10px;
				margin-bottom: 15px;

				p {
					position: absolute;
					top: -8px;
					left: 5px;
					background: #fff;
					font-size: 12px;
				}

				div {
					border: 1px solid black;
					padding: 5px;
				}

				&_date {
					div {
						border: none;
					}

					border: 1px solid black;

					i {
						right: 15px;
					}
				}
			}

			select {
				padding: 10px;
				cursor: pointer;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			button {
				padding: 0.75rem 1.5rem;
				background: #f1e6e6;
				border: 1px solid #c4c4c4;
				border-radius: 1rem;
				font-size: 1.125rem;
				cursor: pointer;
			}
		}
	}
</style>

<style lang="scss">
	.pagination {
		display: flex;
		margin-top: 30px;
	}

	.page {
		cursor: pointer;
		background-color: #4444b7;
		margin-right: 10px;
		color: #fff;

		a {
			display: inline-block;
			padding: 10px;
		}

		&.active {
			background-color: #0909f3;
		}
	}

	.Select_select {
		max-width: 30px;
	}
</style>
